.userSettingContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    // width: 100%;
}

.settingItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px
}