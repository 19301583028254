.searchBar {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
  background: #cab2e8;

  .collapseableContainer {
    padding: 6px 10px;
    // text-transform: uppercase;

  }

  .optionLabel {
    padding: 6px 10px;
    text-decoration: underline;
  }
}

.childContainer {
  max-height: 30vh;
  overflow: auto;
  padding: 5px 0px;
  border: 2px solid rgb(237, 231, 246);
  border-top: none;
}


// .childContainer::-webkit-scrollbar {
//   width: 0px;
//   /* Remove scrollbar width */
//   background: transparent;
//   /* Hide scrollbar background */
// }

// .childContainer::-webkit-scrollbar-thumb {
//   display: none;
//   /* Hide scrollbar thumb */
// }