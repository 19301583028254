.searchBarContainer {
  position: relative;
  border: 1px solid #cac4c4;
  border-radius: 50px;
  align-items: center;
  display: flex;
  padding: 2px 20px;
  width: 90%;

  .iconContainer {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    position: absolute;
    right: 0;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}