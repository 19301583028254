.wareHouseFormContainer {
    max-width: 75%;
    margin: 20px auto;
}

.wareHouseProfileContainer {
    height: 15%;
    display: flex;
    align-items: center;

    img {
        width: 70%;
        margin: 10px;
        object-fit: contain;
    }
}