.profilePhotoContainer{
  background-color: #337180;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Ensure this is equal to width */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  overflow: hidden;
  margin-right: 10px;
  img{
    width: 50px;
    object-fit: contain;
  }
}