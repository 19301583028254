.poFormContainer {
    max-width: 75%;
    margin: 10px auto;
    height: 100%;
    overflow: auto;
    .poFormHeader{
        position: sticky;
        z-index: 100;
        top: 0;
        background-color: #fff;
    }
}

.poProfileContainer {
    height: 15%;
    display: flex;
    align-items: center;

    img {
        width: 70%;
        margin: 10px;
        object-fit: contain;
    }
}

.poFormContainer::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar width */
  background: transparent;
  /* Hide scrollbar background */
}

.poFormContainer::-webkit-scrollbar-thumb {
  display: none;
  /* Hide scrollbar thumb */
}