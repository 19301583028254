.sidemenuContainer {
  height: 100%;
  background: #3b0082;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: width 0.3s ease-in-out;

  &.collapsed {
    align-items: center;
  }

  .sideMenuToggle {
    position: absolute;
    top: 10px;
    right: -10px;
    z-index: 100;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 8px;
  }

  .icon {
    transition: transform 0.3s ease-in-out;
  }

  .icon.rotated {
    transform: rotate(180deg);
  }

  .logoContainer{
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userProfileContainer{
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 50px;
      margin-bottom: 2px;
    }
  }
  .userPhotoContainer{
    width: 50px;
    height: 50px;
    img{
      width: 40px;
      object-fit: contain;
    }
  }

  .menuItem {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 10px 0px;

    &.selectedMenu {
      border: 2px solid #6f44a2;
      background: #5a2996;
      border-radius: 5px;
    }
  }
}