.mainTableContainer {
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;

    .tableHeader {
        position: sticky;
        z-index: 100;
        top: 0;
        // text-transform: capitalize;
        background-color: #cab2e8;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        border: 2px solid #cab2e8;
    }

    .tableBody {
        border: 2px solid rgb(237, 231, 246);
        border-top: none;
    }

    .tableCell {
        white-space: nowrap;
        font-size: 15px;
        font-weight: 500;
    }

    .tableLoadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%
    }
}