.mainLayoutContainer{
    position: absolute;
    left: 0;
    right: -100.62%;
    top: 0%;
    bottom: 65.73%;
    // background-image: url("../../assets/bg-home.jpg");
    // background-blend-mode: overlay;
    // background-color: #7666dc;
    // background-size: 100% 100%;
    // background-position: right;
    width: 100%;
    height: 300px;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.mainLayoutContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(234 234 234); /* Purple overlay color */
    mix-blend-mode: overlay; /* Blending mode */
    z-index: 1;
}
.sidebar {
    transition: width 0.3s ease-in-out;
}