.loginContainer{
    position: absolute;
    left: 0;
    right: -100.62%;
    top: 0%;
    bottom: 65.73%;
    background-image: url("../../assets/login.jpeg");
    background-blend-mode: overlay;
    background-color: #7666dc;
    background-size: 100% ;
    background-position: right;
    width: 100%;
    height: 100vh;
    flex: 1;
    z-index: -1;
    display: flex;
    overflow: hidden;
}

.loginTitleContainer{
    height: 25%;
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
      width: 50px;
      margin-bottom: 2px;
    }
  }