.not-found {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #3f455c;
    justify-content: center;
    font-weight: 600;

    /*
    .logo-img {
      width: 250px;
      img {
        width: 100%;
      }
    } */
    .text-404 {
        font-size: 4rem;
        font-weight: bold;
    }

    .text-main {
        font-size: 1.2rem;
        padding: 41px 0px;
        width: 100%;
        border-bottom: solid 1px #3f455c3d;
        margin-bottom: 15px;
    }
}